export const pushHistoryState = (
  url: string,
  {
    pathname,
    additionalState = {},
    title = "",
  }: {
    pathname?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalState?: any;
    title?: string;
  } = {},
): void => {
  let asPath = pathname ?? url.replace(location.origin, "").split("?")[0];

  if (asPath) {
    asPath = location.pathname;
  }

  history.pushState({ as: asPath, ...additionalState }, title, url);
};

export const replaceHistoryState = (
  url: string,
  {
    pathname,
    additionalState = {},
    title = "",
  }: {
    pathname?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalState?: any;
    title?: string;
  } = {},
): void => {
  let asPath = pathname ?? url.replace(location.origin, "").split("?")[0];

  if (asPath) {
    asPath = location.pathname;
  }

  history.replaceState({ as: pathname, ...additionalState }, title, url);
};
