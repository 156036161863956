import { Store } from "~/bff/types/Store";
import { StoreAddress } from "~/bff/types/StoreAddress";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { Nullable } from "~/types/general.types";

export const formatStoreTitle = (
  store: Nullable<Store> | undefined,
  locale: string,
): string => {
  const featureFlags = AzureConfigurator.getFeatureFlags(locale);
  let result = `${store?.name} ${store?.geomodifier}`;
  if (featureFlags?.displayRegion?.enabled && store?.address?.region) {
    result = result.concat(`, ${store?.address?.region}`);
  }

  return result;
};

export const formatStoreName = (
  store: Store | undefined | null,
  locale: string,
): string | undefined => {
  if (!store) {
    return undefined;
  }

  const featureFlags = AzureConfigurator.getFeatureFlags(locale);
  let result = store.geomodifier ?? store.name;
  if (featureFlags?.displayRegion?.enabled && store?.address?.region) {
    result = result?.concat(`, ${store?.address?.region}`);
  }

  return result ?? undefined;
};

export const formatStoreAddress = (
  store: Store | undefined | null,
  locale: string,
  isRenderSecondLine = true,
): string | undefined => {
  if (!store) {
    return undefined;
  }
  const featureFlags = AzureConfigurator.getFeatureFlags(locale);
  const parts = [
    store?.address?.line1,
    isRenderSecondLine ? store?.address?.line2 : "",
    store?.address?.city,
  ];

  if (featureFlags?.displayRegion?.enabled) {
    parts.push(store.address.region);
  }

  return parts.filter((i) => i).join(", ");
};

export const formatCityLine = (
  address: StoreAddress | undefined,
  locale: string,
): string => {
  const featureFlags = AzureConfigurator.getFeatureFlags(locale);

  return address?.region && featureFlags?.displayRegion?.enabled
    ? `${address.city}, ${address.region} ${address.postalCode}`
    : `${address?.city} ${address?.postalCode}`;
};
