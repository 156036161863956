import moment, { Moment } from "moment";

import { StoreOpenIntervals } from "~/bff/types/StoreOpenIntervals";

const MIDNIGHT_00_HOURS = "00";
const MIDNIGHT_24_HOURS = "24";

const parseInterval = (interval?: string) =>
  typeof interval === "string" ? Number.parseFloat(interval) : 0;

export const isOpen = (now: Moment, hours?: StoreOpenIntervals): boolean => {
  if (hours?.isClosed) {
    return false;
  }

  const end0IntervalsUnparsed = hours?.openIntervals?.[0]?.end?.split(":")?.[0];
  const end0IntervalsParsed = parseInterval(
    hours?.openIntervals?.[0]?.end?.split(":")?.[0],
  );

  const end1IntervalsParsed = parseInterval(
    hours?.openIntervals?.[0]?.end?.split(":")?.[1],
  );
  const start0IntervalsParsed = parseInterval(
    hours?.openIntervals?.[0]?.start?.split(":")?.[0],
  );
  const start1IntervalsParsed = parseInterval(
    hours?.openIntervals?.[0]?.start?.split(":")?.[1],
  );

  const start = moment(now);
  const closingHours =
    end0IntervalsUnparsed === MIDNIGHT_00_HOURS
      ? +MIDNIGHT_24_HOURS
      : +end0IntervalsParsed;

  if (!hours?.openIntervals || hours?.isClosed) {
    return false;
  }

  start.hours(start0IntervalsParsed);
  start.minutes(start1IntervalsParsed);

  const end = moment(now);
  end.hours(closingHours);
  end.minutes(end1IntervalsParsed);

  return now.isBetween(start, end);
};

export default isOpen;
