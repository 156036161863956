"use client";

import React, { FC } from "react";

import { UniversalComponent } from "~/components/universal-component/component";
import { commonComponentsConfig } from "~/constants/components-configs/common-config";
import { storeLocatorPageConfig } from "~/constants/components-configs/store-locator-page-config";
import { FetchPageReturnType } from "~/transport/types";

export const PageClientComponent: FC<{ pageContent: FetchPageReturnType }> = ({
  pageContent,
}) => {
  const { component, props, children, _meta: meta } = pageContent;

  const componentConfigs = [storeLocatorPageConfig, commonComponentsConfig];

  return (
    <UniversalComponent
      meta={meta}
      component={component ?? undefined}
      elements={children ?? null}
      props={{ ...props }}
      componentsConfigs={componentConfigs}
    />
  );
};
