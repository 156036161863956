import { useContext } from "react";

import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Store } from "~/bff/types/Store";
import { AuthContext } from "~/context/auth/context";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";
import { Nullable } from "~/types/general.types";

interface useClickAndCollectTrialStoreReturnType {
  shouldDisplayCCTag: boolean;
}

export const useClickAndCollectTrialStore = (
  store?: Nullable<Store> | undefined,
): useClickAndCollectTrialStoreReturnType => {
  const { locale } = useParams<GlobalPathParams>();
  const config = useAzureConfigurator(locale);
  const authContext = useContext(AuthContext);

  const isClickAndCollectEnabled = config?.featureFlags?.clickAndCollect?.enabled;
  const isClickAndCollectStore = store?.clickAndCollectStore;
  const isClickAndCollectEnabledStore =
    isClickAndCollectEnabled && isClickAndCollectStore;
  const isStoreMarkedAsTrial = store?.trial;

  const shouldDisplayCCTag =
    (authContext?.isMemberOfTrialStoreGroup
      ? isClickAndCollectEnabledStore
      : isClickAndCollectEnabledStore && !isStoreMarkedAsTrial) ?? false;

  return {
    shouldDisplayCCTag,
  };
};
